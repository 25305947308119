<template>
    <div class="admin-competition-reports overflow-hidden full-height">
        <!-- <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else> -->

        <v-container fluid class="pa-6 full-height">
            <v-btn color="primary" @click="createPrintReport" v-if="competition.data.type == 'print'"
                >Create Print Report</v-btn
            >

            <!-- <v-btn color="primary" @click="moveEntries">Move Entries</v-btn> -->
            <app-table
                ref="table"
                v-model="selected"
                :loading="loading"
                show-select
                :form="form"
                :title="competition.title"
                :items="entries"
                :headers="headers"
                :menu="menu"
                :items-per-page="15"
                :buttons="buttons"
                @close="close"
                @export-csv="exportCSV"
                @auto-add-awards="autoAddAwards"
                @add-awards="addAwards"
                @remove-awards="removeAwards"
                @send-awards="sendAwards"
                @add-tags="addTags"
                @remove-tags="removeTags"
                @finalise-scores="finaliseScores"
                @publish-scores="publishScores"
                @unpublish-scores="unpublishScores"
                @filtered="getFiltered"
            ></app-table>
        </v-container>
        <!-- </template> -->

        <content-list-dialog
            v-model="tags"
            ref="tagDialog"
            title="Select Tags"
            type="tag"
            @confirm="confirmTags"
        ></content-list-dialog>

        <content-list-dialog
            v-model="awards"
            ref="awardDialog"
            title="Select Awards"
            type="award"
            @confirm="confirmAwards"
        ></content-list-dialog>

        <auto-awards-dialog
            v-model="awards"
            ref="autoAwardDialog"
            title="Auto Add Awards"
            type="award"
            @confirm="confirmAutoAwards"
        ></auto-awards-dialog>

        <process-dialog ref="processDialog" :title="processTitle" :subtitle="processSubtitle"></process-dialog>

        <alert-dialog ref="finaliseDialog" title="Finalise Scores" @confirm="confirmFinalise"></alert-dialog>
        <alert-dialog ref="publishDialog" title="Publish Scores" @confirm="confirmPublish"></alert-dialog>
        <alert-dialog ref="unpublishDialog" title="Unpublish Scores" @confirm="confirmUnpublish"></alert-dialog>
    </div>
</template>

<script>
// Services
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";
import Contact from "@/modules/app-module/contact";
import Competition from "@/modules/app-module/competition";
import ApiGateway from "@/modules/app-module/api-gateway";

// Components
import AppTable from "@/components/table/app-table.vue";
import ContentListDialog from "@/components/dialogs/content-list-dialog.vue";
import AutoAwardsDialog from "@/components/dialogs/auto-awards-dialog.vue";
import ProcessDialog from "@/components/dialogs/process-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";

export default {
    name: "admin-competition-reports",

    meta: {
        title: "Reports",
    },

    components: {
        AppTable,
        ContentListDialog,
        ProcessDialog,
        AlertDialog,
        AutoAwardsDialog,
    },

    props: {},

    data() {
        return {
            awards: [],
            cache: 0,
            tab: 0,
            loading: true,
            selected: [],
            tags: [],
            entries: [],
            scorecards: [],
            contacts: [],
            competition: {},
            filtered: [],
            processTitle: "",
            processSubtitle: "",
            buttons: [
                {
                    text: "Close",
                    color: "grey",
                    function: "close",
                },
                // {
                //     text: "Export CSV",
                //     color: "primary",
                //     function: "export-csv",
                // },
                // {
                //     text: "Export Images",
                //     color: "dark",
                //     function: "export-images",
                // },
            ],
            originalHeaders: [
                {
                    text: "Image",
                    value: "image",
                    // parent: "data",
                    type: "image-cell",
                    width: "1%",
                },
                // {
                //     text: "ID",
                //     value: "_id",
                //     type: "text-cell",
                //     width: "1%",
                // },
                {
                    text: "Entry Code",
                    value: "entryCode",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Artist",
                    value: "managedAuthor",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Email Address",
                    value: "emails",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                    isArray: true,
                },
                {
                    text: "Address",
                    value: "address",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Feedback (Requested)",
                    value: "feedback",
                    parent: "data",
                    type: "boolean-cell",
                    width: "1%",
                },
                {
                    text: "Feedback (Count)",
                    value: "feedbackCount",
                    // parent: "posts",
                    type: "score-cell",
                    width: "1%",
                },
                {
                    text: "Tags",
                    value: "tagsArray",
                    type: "tag-cell",
                    width: "1%",
                    isArray: true,
                },
                {
                    text: "Entrant Type",
                    value: "entrant",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Camera Used",
                    value: "cameraUsed",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Category",
                    value: "category",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
            ],
            headers: [],
            form: {
                fields: [
                    {
                        title: "Status",
                        key: "status",
                    },
                ],
            },
        };
    },

    computed: {
        exportItems() {
            return this.selected.length ? this.selected : this.filtered.length ? this.filtered : this.entries;
        },
        menu() {
            let menu = [];

            menu.push({
                icon: "mdi-file-delimited-outline",
                text: "Export CSV",
                function: "export-csv",
            });

            menu.push({
                divider: true,
            });

            menu.push({
                icon: "mdi-auto-fix",
                text: "Auto Add Awards",
                function: "auto-add-awards",
            });

            menu.push({
                icon: "mdi-star-plus",
                text: "Add Awards",
                function: "add-awards",
                disabled: this.selected.length ? false : true,
            });

            menu.push({
                icon: "mdi-star-remove",
                text: "Remove Awards",
                function: "remove-awards",
                disabled: this.selected.length ? false : true,
            });

            menu.push({
                icon: "mdi-email",
                text: "Send Awards Email",
                function: "send-awards",
                disabled: this.selected.length ? false : true,
            });

            menu.push({
                divider: true,
            });

            menu.push({
                icon: "mdi-tag-plus-outline",
                text: "Add Tags",
                function: "add-tags",
                disabled: this.selected.length ? false : true,
            });

            menu.push({
                icon: "mdi-tag-minus-outline",
                text: "Remove Tags",
                function: "remove-tags",
                disabled: this.selected.length ? false : true,
            });

            menu.push({
                divider: true,
            });

            menu.push({
                icon: "mdi-calculator-variant-outline",
                text: "Finalise Scores",
                function: "finalise-scores",
            });

            menu.push({
                icon: "mdi-check-circle-outline",
                text: "Publish Scores",
                function: "publish-scores",
                disabled: !this.exportItems.every((item) => item?.data?.finalised),
            });

            menu.push({
                icon: "mdi-minus-circle-outline",
                text: "Unpublish Scores",
                function: "unpublish-scores",
                disabled: !this.exportItems.every((item) => item?.data?.finalised),
            });

            return menu;
        },
        isOnline() {
            return this.competition?.data?.judgingType == "online";
        },
        isLive() {
            return this.competition?.data?.judgingType == "live";
        },
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },
    },

    methods: {
        //FUNCTION FOR DEV ONLY instead of node script
        async moveEntries() {
            const digitalLiveComp = "64b73b556dd4600aa43da992";
            const digitalONLINEComp = "6474323bb2c90336137b7f8e";
            const ASTRO = "64b7284a6927ae34585236d6";
            //Get All detail Sheets
            let entryQuery = {
                _type: "article",
                definition: "entry",
                status: {
                    $in: ["active", "draft", "archived"],
                },
                $or: [
                    {
                        "data.competition": ASTRO,
                    },
                    {
                        "data.competition": `ObjectId(${ASTRO})`,
                    },
                    {
                        "data.competition._id": ASTRO,
                    },
                    {
                        "data.competition._id": `ObjectId(${ASTRO})`,
                    },
                ],
            };

            const compEntries = await this.$fluro.api.post("/content/_query", entryQuery).then(({ data }) => data);
            console.log("compentries", compEntries);
            let errors = [];
            let success = [];
            let errorCount = 0;
            let successCount = 0;
            for (const entry of compEntries) {
                const code = entry.data.entryCode;
                const cleanCode = code.slice(0, -2);
                const finalScore = entry.data.score;
                // console.log("Code = ", code, "Clean", cleanCode, "FinalScore", finalScore);

                const single = {
                    _type: "article",
                    definition: "entry",
                    status: {
                        $in: ["active"],
                    },
                    "data.entryCode": cleanCode,
                };

                const singleEntry = await this.$fluro.api.post("/content/_query", single).then(({ data }) => data);

                if (!singleEntry.length) {
                    console.log("ERROR FINDING ENTRY", cleanCode);
                    errors.push(singleEntry);
                    errorCount++;
                } else {
                    const singleEntryId = singleEntry[0]._id;
                    console.log("GOT ID = ", singleEntryId);
                    // if (singleEntryId == "64a01f13e39e6265685e7d00") {
                    const payload = {
                        data: {
                            score: finalScore,
                        },
                    };
                    console.log("SENDING PAYLOAD", payload);
                    const updated = await this.$fluro.api
                        .put(`/content/entry/${singleEntryId}`, payload)
                        .then(({ data }) => data);
                    success.push(singleEntry);
                    successCount++;
                    console.log("Updated Entry", updated);
                    // }
                }
            }

            console.log("FINISHED", "ERRORS:", errorCount, "SUCCESS:", successCount);

            console.log("FINISHED", "ERROR IDS:", errors, "SUCCESSIDs:", success);
        },
        //Testing G63NKF25

        //Broken  WJ4WTN CQUG8B
        //NO SCORES LEFA8C25  FCZCAS25   S7M4HS25
        async sendAwards() {
            const entires = this.selected;
            console.log("This selected", this.selected);
            this.$refs.processDialog.open(this.selected);
            this.processTitle = "Sending Emails";
            const emailPromise = entires.map(async (entry) => {
                const awardIds = entry.data.awards.map((award) => award?._id);

                // Ensure that all promises for awardURLs are resolved before proceeding
                const awardURLs = await Promise.all(awardIds.map(async (id) => await this.getImage(id)));
                const entryImageURL = await this.getImage(entry?.image?._id);
                this.processSubtitle = "Sending awards to " + entry.managedAuthor;
                console.log("Got awards", awardIds, awardURLs);

                const payload = {
                    entryImageURL: entryImageURL,
                    entryCode: entry.entryCode,
                    awards: awardURLs,
                    category: entry.category,
                    emails: entry.emails,
                    managedAuthor: entry.managedAuthor,
                    competition: entry?.data?.competition,
                    orgData: this.orgData,
                };

                console.log("Payload", payload);

                const response = await this.$fluro.api
                    .post(`/reaction/spark/64a7422a43b043181faadc21`, payload)
                    .then(({ data }) => data);

                this.$refs.processDialog.increment();
                console.log(response);

                return response; // return response so it can be used later
            });

            // Wait for all promises to resolve
            const responses = await Promise.all(emailPromise);
            console.log(responses);

            this.$refs.processDialog.close();
        },

        getImage(image) {
            if (image) {
                return this.$fluro.asset.imageUrl(image);
            }
        },
        appendAwardHeaders() {
            let awards = this.competition.data.awards;
            console.log("LIST OF AWARDS", awards);

            this.headers.splice(4, 0, {
                text: "Awards",
                value: "awards",
                parent: "data",
                type: "image-cell",
                width: "1%",
            });
        },
        reset() {
            this.selected = [];
            //Needed this as the component isnt rendered in time sometimes
            if (this.$refs?.table) {
                console.log("REFS", this.$refs);
                this.$refs.table.selected = this?.selected || null;
            }

            this.tags = [];
            this.entries = [];
            this.scorecards = [];
            this.contacts = [];
            this.competition = {};
            this.filtered = [];
            this.processTitle = "";

            this.headers = [...new Set(this.originalHeaders)];
        },
        async init() {
            this.loading = true;

            await this.$fluro.resetCache();

            this.reset();
            try {
                // Competition
                this.competition = await Competition.get(this.$route.params.id).then(({ data }) => data);

                //////////////////////////////////////////////////

                // Entries

                let entryQuery = {
                    _type: "article",
                    definition: "entry",
                    status: {
                        $in: ["active", "draft", "archived"],
                    },
                    $or: [
                        {
                            "data.competition": this.$route.params.id,
                        },
                        {
                            "data.competition": `ObjectId(${this.$route.params.id})`,
                        },
                        {
                            "data.competition._id": this.$route.params.id,
                        },
                        {
                            "data.competition._id": `ObjectId(${this.$route.params.id})`,
                        },
                    ],
                };

                let params = {};

                if (this.isOnline) {
                    params.appendPosts = "all";
                }

                this.entries = await Entry.query(entryQuery, { params }).then(({ data }) => data);

                //////////////////////////////////////////////////

                // Contacts

                let userIds = this.entries.map(({ managedAuthor }) => {
                    return managedAuthor?._id;
                });
                userIds = [...new Set(userIds)];

                let contactQuery = {
                    _type: "contact",
                    "data.personaId": {
                        $in: userIds,
                    },
                };

                this.contacts = await Contact.query(contactQuery).then(({ data }) => data);

                //////////////////////////////////////////////////

                if (this.isOnline) {
                    await this.getOnlineScores();
                }

                if (this.isLive) {
                    let ids = this.entries.map(({ _id }) => `ObjectId(${_id})`);

                    let scorecardQuery = {
                        _type: "article",
                        definition: "scoreCard",
                        status: {
                            $in: ["active", "draft", "archived"],
                        },
                        "data.entry": {
                            $in: ids,
                        },
                    };

                    this.scorecards = await ScoreCard.query(scorecardQuery).then(({ data }) => data);
                    console.log("SCORECARDS", this.scorecards);

                    this.getLiveScores();
                }

                this.headers.push({
                    text: "Status",
                    value: "status",
                    type: "status-cell",
                    width: "1%",
                });

                this.appendAwardHeaders();

                console.log("ENTRIES", this.entries);
                console.log("COMPETITION", this.competition);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        async getOnlineScores() {
            // Getting the judges from the competition
            console.log("Hello");
            let categoryJudges = this?.competition?.data?.categoryJudges || [];

            let judges = categoryJudges?.map(({ judges }) => judges)?.flat() || [];
            judges = [...new Map(judges.map((item) => [item["_id"], item])).values()];

            judges.forEach((judge) => {
                this.headers.push({
                    text: judge.title,
                    value: judge._id,
                    type: "score-cell",
                    width: "1%",
                });
            });

            //////////////////////////////////////////////////

            // Touching up the entries

            this.entries = await Promise.all(
                this.entries.map(async (entry) => {
                    let { image, category, entryCode } = entry.data;

                    let foundContact = this.contacts.find(
                        (contact) => contact?.data?.personaId == entry?.managedAuthor?._id
                    );

                    //////////////////////////////////////////////////

                    // Setup entry

                    let obj = {
                        ...entry,
                        tagsArray: entry?.tags?.map(({ title }) => title) || [],
                        image,
                        entryCode,
                        managedAuthor: entry?.managedAuthor?.title || "",
                        score: entry?.data?.score || "",
                        cameraUsed: entry?.data?.cameraUsed || "",
                        finalised: entry?.data?.finalised || false,
                        published: entry?.data?.published || false,
                        emails: foundContact?.emails || "",
                        address: foundContact?.data?.address || "",
                        category: category?.title || "",
                        entrant: foundContact?.data?.entrant || "",
                        feedbackCount: entry?.posts?.feedback?.count || 0,
                        studentAward: foundContact?.data?.studentAward || false,
                        masterAward: foundContact?.data?.masterAward || false,
                        grandMasterAward: foundContact?.data?.grandMasterAward || false,
                    };

                    //////////////////////////////////////////////////

                    // Add scores/abstains

                    let scores = entry?.posts?.score?.posts || [];
                    let submitted = scores.filter((score) => score?.data?.submitted) || [];
                    let abstains = entry?.posts?.abstain?.posts || [];

                    let total = 0;
                    let scoreArray = [];

                    judges.forEach((judge) => {
                        let score = scores?.find((score) => score?.managedAuthor == judge?._id);
                        let abstain = abstains?.find((abstain) => abstain?.managedAuthor == judge._id);

                        let awaitingScore =
                            !score &&
                            categoryJudges.some(
                                (item) =>
                                    (item?.category?._id || item?.category) ==
                                        (entry?.data?.category?._id || entry?.data?.category) &&
                                    item?.judges.some((itemJudge) => (itemJudge?._id || itemJudge) == judge?._id)
                            );

                        obj[judge._id] = abstain
                            ? "Abstained"
                            : awaitingScore
                            ? "Awaiting score"
                            : score
                            ? score?.data
                            : "";

                        if (score?.data?.submitted) {
                            //Custom Scoring
                            scoreArray.push(score?.data?.score || 0);
                            total += parseInt(score?.data?.score) || 0;
                        }
                    });
                    //Custom scoring uses a string from fluro as custom JS code to made the calcs
                    const payload = {
                        numbers: scoreArray,
                    };

                    //Final score will always be the calculation they have chosen
                    let calculatedScore = null;
                    //Skip it if it calculated average, too many calls is crashing the system (2000 in pet entries)
                    if (
                        this.competition?.data?.formula?._id != "644b0f7943b8db57ca7e4b61" &&
                        typeof this.competition?.data?.formula?._id != "undefined"
                    ) {
                        try {
                            if (scoreArray.length > 0) {
                                await this.$fluro.api
                                    .post(`/reaction/spark/${this.competition?.data?.formula?._id}`, payload)
                                    .then(({ data }) => data);
                            } else {
                                console.log("Skipping calculation, no data");
                            }
                        } catch (error) {
                            console.log(error);
                            calculatedScore = 0;
                        }
                    } else {
                        console.log("SKIPPING REACTION, FORMULA IS AVERAGE");
                    }

                    let average = Math.round(total / submitted.length).toFixed(2);

                    //////////////////////////////////////////////////
                    obj.calculated = calculatedScore?.result | average | null;
                    obj.average = average | null;
                    obj.total = total | null;

                    //////////////////////////////////////////////////

                    return obj;
                })
            );

            if (judges.length) {
                this.headers.push(
                    {
                        text: "Average Score",
                        value: "average",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Total Score",
                        value: "total",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Calculated Score",
                        value: "calculated",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Final Score",
                        value: "score",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Finalised",
                        value: "finalised",
                        type: "boolean-cell",
                        width: "1%",
                    },
                    {
                        text: "Published",
                        value: "published",
                        type: "boolean-cell",
                        width: "1%",
                    }
                );
            }
        },
        getLiveScores() {
            // Touching up the entries

            this.entries = this.entries.map((entry) => {
                let { image, category, entryCode } = entry.data;

                let foundContact = this.contacts.find(
                    (contact) => contact?.data?.personaId == entry?.managedAuthor?._id
                );

                //////////////////////////////////////////////////

                // Setup entry

                let obj = {
                    ...entry,
                    tagsArray: entry?.tags?.map(({ title }) => title) || [],
                    image,
                    entryCode,
                    managedAuthor: entry?.managedAuthor?.title || "",
                    score: entry?.data?.score || "",
                    cameraUsed: entry?.data?.cameraUsed || "",
                    finalised: entry?.data?.finalised || false,
                    published: entry?.data?.published || false,
                    emails: foundContact?.emails || "",
                    address: foundContact?.data?.address || "",
                    category: category?.title || "",
                    entrant: foundContact?.data?.entrant || "",
                    studentAward: foundContact?.data?.studentAward || false,
                    masterAward: foundContact?.data?.masterAward || false,
                    grandMasterAward: foundContact?.data?.grandMasterAward || false,
                };

                //////////////////////////////////////////////////

                // Add judges

                //////////////////////////////////////////////////

                // Add scorecards

                let scorecards = this.scorecards.filter((card) => card?.data?.entry?._id == entry._id);

                if (scorecards.length) {
                    let scorecard = scorecards.sort((a, b) => b?.data?.round - a?.data?.round)[0];

                    obj.round = scorecard?.data?.round;
                    obj.average = scorecard?.data?.average;
                    obj.calculated = scorecard?.data?.calculated;
                    obj.modified = scorecard?.data?.modified;
                    obj.reviewed = scorecard?.data?.reviewed;
                }

                //////////////////////////////////////////////////

                return obj;
            });

            if (this.scorecards?.length) {
                this.headers.push(
                    {
                        text: "Round Finalised",
                        value: "round",
                        type: "text-cell",
                        width: "1%",
                    },
                    {
                        text: "Average Score",
                        value: "average",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Calculated Score",
                        value: "calculated",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Modified Score",
                        value: "modified",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Reviewed",
                        value: "reviewed",
                        type: "boolean-cell",
                        width: "1%",
                    },
                    {
                        text: "Final Score",
                        value: "score",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Finalised",
                        value: "finalised",
                        type: "boolean-cell",
                        width: "1%",
                    },
                    {
                        text: "Published",
                        value: "published",
                        type: "boolean-cell",
                        width: "1%",
                    }
                );
            }
        },
        close() {
            this.$router.push({ name: "admin.competition", params: { id: this.$route.params.id } });
        },
        getFiltered(e) {
            this.filtered = e;
        },
        async exportCSV() {
            let data = {
                title: this.competition.title,
                items: this.exportItems,
                headers: this.headers,
            };

            await this.$app.exportCSVItems(data);
        },
        autoAddAwards() {
            this.$refs.autoAwardDialog.open(null, "add");
            this.processTitle = "Auto Adding Awards";
        },
        addAwards() {
            this.$refs.awardDialog.open(null, "add");
            this.processTitle = "Adding Awards";
        },
        removeAwards() {
            this.$refs.awardDialog.open(null, "remove");
            this.processTitle = "Removing Awards";
        },
        addTags() {
            this.$refs.tagDialog.open(null, "add");
            this.processTitle = "Adding Tags";
        },
        removeTags() {
            this.$refs.tagDialog.open(null, "remove");
            this.processTitle = "Removing Tags";
        },
        async confirmTags(tags, index, key) {
            this.$refs.tagDialog.close();

            this.$refs.processDialog.open(this.selected);

            let loop = async () => {
                for (var i = 0; i < this.selected.length; i++) {
                    try {
                        const item = this.selected[i];

                        let payload = {};

                        let mappedItemTags = item?.tags?.map(({ _id }) => _id) || [];
                        let mappedSelectedTags = tags?.map(({ _id }) => _id) || [];

                        if (key == "add") {
                            if (!mappedItemTags || !mappedItemTags?.length) {
                                payload.tags = tags.map(({ _id }) => _id);
                            } else {
                                let array = mappedItemTags.concat(mappedSelectedTags);
                                payload.tags = array.filter((v, i, a) => a.indexOf(v) === i);
                            }
                        }

                        if (key == "remove") {
                            if (mappedItemTags?.length) {
                                payload.tags = mappedItemTags.filter((i) => !mappedSelectedTags.includes(i));
                            }
                        }

                        // await new Promise((resolve) => setTimeout(resolve, 1000));

                        let updated = await Entry.update(item._id, payload).then(({ data }) => data);
                        console.log("UPDATED", updated);

                        this.$refs.processDialog.increment();
                    } catch (err) {
                        console.log("PROCESS ERROR", err);
                    }
                }
            };

            await loop();

            // Buffer to let 100% sit for a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            this.$refs.processDialog.close();

            await this.init();
        },
        async confirmAutoAwards(payload) {
            this.$refs.autoAwardDialog.close();

            const finalisedEntries = this.entries.filter(
                (entry) => entry.score >= payload.lower && entry.score <= payload.upper && entry.finalised
            );

            const updatePromises = finalisedEntries.map((entry) => {
                const awardsIds = entry?.data?.awards ? entry.data.awards.map((a) => a._id) : [];
                const uniqueAwards = Array.from(new Set([...awardsIds, payload.award]));
                console.log("Unique", uniqueAwards);
                const updatedEntry = {
                    data: {
                        awards: uniqueAwards,
                    },
                };
                this.$refs.processDialog.increment();
                return this.$fluro.api
                    .put(`/content/entry/${entry._id}`, updatedEntry)
                    .then((response) => response.data);
            });

            this.$refs.processDialog.open(updatePromises);

            const responses = await Promise.all(updatePromises);
            this.$refs.processDialog.close();
            console.log("RESPONSE", responses);
            await this.init();
        },
        async confirmAwards(awards, index, key) {
            this.$refs.awardDialog.close();

            this.$refs.processDialog.open(this.selected);

            let loop = async () => {
                for (var i = 0; i < this.selected.length; i++) {
                    try {
                        const item = this.selected[i];

                        let payload = { data: {} };

                        let mappedItemAwards = item?.data.awards?.map(({ _id }) => _id) || [];
                        let mappedSelectedAwards = awards?.map(({ _id }) => _id) || [];

                        if (key == "add") {
                            if (!mappedItemAwards || !mappedItemAwards?.length) {
                                payload.data.awards = awards.map(({ _id }) => _id);
                            } else {
                                let array = mappedItemAwards.concat(mappedSelectedAwards);
                                payload.data.awards = array.filter((v, i, a) => a.indexOf(v) === i);
                            }
                        }

                        if (key == "remove") {
                            if (mappedItemAwards?.length) {
                                payload.data.awards = mappedItemAwards.filter((i) => !mappedSelectedAwards.includes(i));
                            }
                        }
                        // await new Promise((resolve) => setTimeout(resolve, 1000));

                        let updated = await Entry.update(item._id, payload).then(({ data }) => data);
                        console.log("UPDATED", updated);

                        this.$refs.processDialog.increment();
                    } catch (err) {
                        console.log("PROCESS ERROR", err);
                    }
                }
            };

            await loop();

            // Buffer to let 100% sit for a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            this.$refs.processDialog.close();

            await this.init();
        },
        finaliseScores() {
            this.$refs.finaliseDialog.open(
                this.exportItems,
                `Are you sure you want to finalise <b>${this.exportItems.length}</b> scores? This will add the final score to the entry. After you have finalised the scores you will be able to edit them before you have to publish them.`
            );
        },
        async confirmFinalise(items) {
            let payload = {};

            try {
                if (this.isOnline) {
                    payload = items.map((item) => {
                        return {
                            _id: item._id,
                            data: {
                                score: item.calculated || item.average,
                                finalised: true,
                            },
                        };
                    });
                }

                if (this.isLive) {
                    payload = items.map((item) => {
                        return {
                            _id: item._id,
                            data: {
                                score: item.modified || item.calculated,
                                finalised: true,
                            },
                        };
                    });
                }

                let batch = await ApiGateway.batch(payload).then(({ data }) => data);

                console.log("BATCH", batch);

                this.$refs.finaliseDialog.close();

                await this.init();
            } catch (err) {
                console.log("FINALISE ERROR", err);
                this.$refs.finaliseDialog.error(err.response.data.message);
            }
        },
        publishScores() {
            this.$refs.publishDialog.open(
                this.exportItems,
                `Are you sure you want to publish <b>${this.exportItems.length}</b> scores? This will make the artist's score available to them in the portal.`
            );
        },
        async confirmPublish(items) {
            try {
                let payload = items.map((item) => {
                    return {
                        _id: item._id,
                        data: {
                            published: true,
                        },
                    };
                });

                let batch = await ApiGateway.batch(payload).then(({ data }) => data);

                console.log("BATCH", batch);

                this.$refs.publishDialog.close();

                await this.init();
            } catch (err) {
                console.log("PUBLISH ERROR", err);
                this.$refs.publishDialog.error(err.response.data.message);
            }
        },
        unpublishScores() {
            this.$refs.unpublishDialog.open(
                this.exportItems,
                `Are you sure you want to unpublish <b>${this.exportItems.length}</b> scores? This will remove the artist's score in the portal.`
            );
        },
        async confirmUnpublish(items) {
            try {
                let payload = items.map((item) => {
                    return {
                        _id: item._id,
                        data: {
                            published: false,
                        },
                    };
                });

                let batch = await ApiGateway.batch(payload).then(({ data }) => data);

                console.log("BATCH", batch);

                this.$refs.unpublishDialog.close();

                await this.init();
            } catch (err) {
                console.log("UNPUBLISH ERROR", err);
                this.$refs.unpublishDialog.error(err.response.data.message);
            }
        },
        async createPrintReport() {},
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
