<template>
    <v-dialog
        v-model="display"
        persistent
        content-class="content-list"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        width="800"
    >
        <v-card class="content-list-dialog no-selection overflow-hidden d-flex flex-column">
            <div class="dialog-error error px-4 flex-grow-1" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3 flex-grow-1">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>
            <v-container v-if="items" class="container-form">
                <v-row>
                    <v-col cols="12">
                        <p class="d-flex justify-center text-center">
                            Select an award and the score-range it belongs to. Then hit okay to apply this award to all
                            entries within the selected score-range.
                        </p>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="lower"
                            class="pa-0 ma-0"
                            type="number"
                            label="Score Lower"
                            min="0"
                            max="99"
                            :rules="lowerRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="upper"
                            class="pa-0 ma-0"
                            type="number"
                            label="Score Upper"
                            max="100"
                            min="1"
                            :rules="upperRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select
                            class="pa-0 ma-0"
                            v-model="selectedAward"
                            item-text="title"
                            item-value="_id"
                            persistent-hint
                            :items="items"
                            label="Awards"
                        >
                            <template #item="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="populateImage(item._id)" width="32" height="32"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <strong class="d-flex justify-center text-center">
                            Lower and upper scores are inclusive. <br />Only adds awards to finalised scores!</strong
                        >
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="actions flex-grow-1 light pa-3">
                <v-btn :color="cancelColor" uppercase @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>
                <v-btn :disabled="isDisabled" :color="actionColor" uppercase @click="confirm">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute zindex-30" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import AppTable from "@/components/table/app-table.vue";

// Packages
import _ from "lodash";

export default {
    name: "content-list-dialog",

    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        title: {
            type: String,
            default() {
                return "Content List";
            },
        },
        type: {
            type: String,
            default() {
                return "article";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        headers: {
            type: Array,
            default() {
                return [
                    {
                        text: "Name",
                        value: "title",
                        type: "text-cell",
                    },
                ];
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    components: {
        AppTable,
    },

    data() {
        return {
            selectedAward: null,
            lowerRules: [(v) => (v >= 0 && v <= 99) || "Score cannot be betwen 0-99"],
            upperRules: [(v) => (v <= 100 && v >= 1) || "Score cannot be between 1-100"],
            lower: null,
            upper: null,
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            index: null,
            key: null,
            items: [],
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        isDisabled() {
            console.log(this.lower < 0 || this.lower > 100, this.upper > 0 || this.upper < 101);
            if (this.lower > this.upper) return true;
            // if (this.upper > 0 || this.upper < 101) return true;
            return false;
        },
    },

    methods: {
        populateImage(item) {
            //Get the image
            const image = this.$fluro.asset.imageUrl(item);
            return image;
        },
        async init(index, key) {
            this.showError = false;
            this.loading = true;

            this.index = index;
            this.key = key;
            //Only get the registered personas

            this.items = await this.$fluro.api.get(`/content/award`).then(({ data }) => data);

            this.items = this.items.filter((p) => p.realms.some((realm) => realm._id == this.$app.liveRealm));

            console.log("ITEMS", this.items);

            this.loading = false;
        },
        async open(index, key) {
            this.init(index, key);
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            const payload = {
                lower: this.lower,
                upper: this.upper,
                award: this.selectedAward,
            };
            this.$emit("confirm", payload);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.lower = null;
            this.upper = null;
            this.selectedAward = null;
            this.display = false;
            this.index = null;
            this.key = null;
            this.selected = [];
        },
    },
};
</script>

<style lang="scss">
.content-list {
    overflow: hidden;
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
}
.container-form {
    max-width: 60%;
}
.content-list-dialog {
    overflow: hidden;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
