<template>
    <v-dialog
        v-model="display"
        persistent
        content-class="content-list"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        width="800"
    >
        <v-card class="content-list-dialog no-selection overflow-hidden d-flex flex-column">
            <div class="dialog-error error px-4 flex-grow-1" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3 flex-grow-1">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <app-table
                :key="loading"
                :singleSelect="singleSelect"
                v-model="selected"
                :items="items"
                :headers="headers"
                show-select
                click-select
                hide-menu
            ></app-table>

            <v-card-actions class="actions flex-grow-1 light pa-3">
                <v-btn :color="cancelColor" uppercase @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>
                <v-btn :disabled="!valid" :color="actionColor" uppercase @click="confirm">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute zindex-30" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import AppTable from "@/components/table/app-table.vue";

// Packages
import _ from "lodash";

export default {
    name: "content-list-dialog",

    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        title: {
            type: String,
            default() {
                return "Content List";
            },
        },
        type: {
            type: String,
            default() {
                return "article";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        headers: {
            type: Array,
            default() {
                return [
                    {
                        text: "Name",
                        value: "title",
                        type: "text-cell",
                    },
                ];
            },
        },
        singleSelect: {
            type: Boolean,
            default() {
                return false;
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    components: {
        AppTable,
    },

    data() {
        return {
            selected: [],
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            index: null,
            key: null,
            items: [],
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        valid() {
            if (!this.selected.length) return false;
            return true;
        },
    },

    methods: {
        async init(index, key) {
            this.showError = false;
            this.loading = true;

            this.index = index;
            this.key = key;
            //Only get the registered personas

            if (this.type == "persona") {
                const currentOrg = this.$store.getters["org/getOrg"];

                //Get All detail Sheets
                const personaPayload = {
                    _type: "contactdetail",
                    definition: "registeredOrg",
                    // "data.activeOrgs": { $in: [currentOrg] }, //TO DO, make this work to increase performance
                };

                const details = await this.$fluro.api.post("/content/_query", personaPayload).then(({ data }) => data);

                //Filter to one that contain only the current org
                const filtered = details.filter((p) => p?.data?.activeOrgs.some((org) => org._id == currentOrg));
                const requiredPersona = filtered.map((p) => {
                    if (p?.managedAuthor) {
                        return p.managedAuthor._id;
                    }
                });
                console.log("here 2");
                //Get all persona that match that _id
                const personas = await this.$fluro.content.getMultiple("persona", requiredPersona).then((res) => res);
                this.items = personas;
            } else {
                this.items = await this.$fluro.api.get(`/content/${this.type}`).then(({ data }) => data);
            }

            if (this?.items[0]?._type == "image") {
                //filter the images by realm to avoid getting awards from other orgs
                this.items = this.items.filter((p) => p.realms.some((realm) => realm._id == this.$app.liveRealm));
                if (this.headers.length < 2) {
                    //Add an image
                    this.headers.push({
                        text: "Image",
                        value: "_id",
                        // parent: "data",
                        type: "image-cell",
                        width: "1%",
                    });
                }
            }

            if (this?.items[0]?._type == "tag") {
                this.items = this.items.filter((p) =>
                    p.realms.some((realm) => realm._id == this.$app.liveRealm || realm._id == this.$app.orgRealm)
                );
            }

            //Filter entries
            if (this.type == "entry") {
                this.items = this.items.filter((p) => p.realms.some((realm) => realm._id == this.$app.liveRealm));
                this.items = this.items.filter((p) => p?.data?.competition?._id == this.$route.params.id);
                this.items = this.items.filter((p) => p?.data?.image || p?.data?.videoSourceFull);
            }
            console.log("ITEMS", this.items);

            if (this.value) this.selected = _.cloneDeep(this.value);

            console.log("SELECTED", this.selected);

            this.loading = false;
        },
        async open(index, key) {
            this.init(index, key);
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.selected, this.index, this.key);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
            this.index = null;
            this.key = null;
            this.selected = [];
        },
    },
};
</script>

<style lang="scss">
.content-list {
    overflow: hidden;
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
}

.content-list-dialog {
    overflow: hidden;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
