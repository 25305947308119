import Vue from "vue";
import axios from "axios";

const instance = axios.create({
    baseURL: "https://hl66letcw8.execute-api.ap-southeast-2.amazonaws.com/dev",
    // baseURL: "http://localhost:3000/dev",
    // timeout: 1000,
});

const batch = (payload) => {
    let token = Vue.prototype.$fluro.auth.getCurrentToken();

    instance.defaults.headers.common.Authorization = token;

    return new Promise((resolve, reject) => {
        return instance
            .post("/batch", payload)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export default {
    batch,
};
